import React from "react";
import List from "@material-ui/core/List";
import { Box, IconButton, Typography, useMediaQuery } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import { useTheme } from "@material-ui/core/styles";
import NavigationItems from "./NavigationItems";
import isomorphicMap from "../../extensions";
import { useStyles } from "./Navigation.style";
import { usePageContext } from "../Context/PageContextProvider";
const Navigation = (props) => {
    const classes = useStyles(props);
    const theme = useTheme();
    const { isServerSideRendering } = usePageContext();
    const isDesktop = isServerSideRendering && typeof prefilledDataSSR !== "undefined"
        ? JSON.parse(prefilledDataSSR.customerDevice)?.isMobile.toString() ===
            "false"
        : useMediaQuery(theme.breakpoints.up("md"), { noSsr: true });
    return (React.createElement("div", { className: classes.appMenuWrapper },
        React.createElement(List, { component: "nav", className: classes.appMenu, disablePadding: true }, isomorphicMap(props.navigation, (item, index) => {
            return (React.createElement(NavigationItems, Object.assign({}, item, { key: index, handleDrawer: props.handleDrawer })));
        })),
        props.myPagesLink && isDesktop && (React.createElement(Box, { className: classes.icon },
            React.createElement(IconButton, { color: "inherit", href: props.myPagesLink, className: classes.menuButton, disableRipple: true },
                props.loginIcon ? (React.createElement("div", { className: classes.loginIcon })) : (React.createElement(PersonIcon, null)),
                React.createElement(Typography, { variant: "body1", component: "p", className: classes.menuButtonText }, props.loginText))))));
};
export default Navigation;
