import { makeStyles, createStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme) => createStyles({
    appMenuWrapper: {
        marginLeft: 0,
        marginRight: 0,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        [theme.breakpoints.up("md")]: {
            overflow: "auto",
            marginLeft: 24,
            marginRight: 24,
        },
    },
    appMenu: {
        rowGap: 0,
        columnGap: 32,
        width: "100%",
        backgroundColor: theme.navigation?.mobileCollapseBackgroundColor ||
            theme.colorCard?.background2,
        [theme.breakpoints.up("md")]: {
            display: "flex",
            flexDirection: "row",
            backgroundColor: theme.colorCard?.background || theme.palette?.background?.default,
            flexWrap: "wrap",
            width: "auto",
        },
        [theme.breakpoints.down("sm")]: {
            overflow: "auto",
        },
        ...theme.customStyling?.Navigation?.appmenu,
    },
    appMenuItems: {
        padding: 0,
    },
    menuButton: {
        padding: 0,
        color: theme.colorCard?.primaryHover || theme.palette?.primary?.dark,
    },
    menuButtonText: {
        paddingLeft: 8,
        ...theme.customStyling?.Navigation?.menuButtonText,
    },
    icon: {
        minWidth: "120px",
        textAlign: "end",
        "& :hover": {
            backgroundColor: "transparent",
            textDecoration: "underline",
        },
        "& .Mui-focusVisible": {
            outline: "auto",
        },
        ...theme.customStyling?.Navigation?.icon,
    },
    loginIcon: (props) => ({
        backgroundImage: `url(${props.loginIcon})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right",
        backgroundPositionY: "center",
        width: 24,
        height: 24,
    }),
}));
